// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---content-pages-blah-mdxb": () => import("./../../../content/pages/blah.mdxb" /* webpackChunkName: "component---content-pages-blah-mdxb" */),
  "component---content-pages-iindex-md": () => import("./../../../content/pages/iindex.md" /* webpackChunkName: "component---content-pages-iindex-md" */),
  "component---node-modules-gatsby-theme-blog-core-src-templates-post-query-js": () => import("./../../../node_modules/gatsby-theme-blog-core/src/templates/post-query.js" /* webpackChunkName: "component---node-modules-gatsby-theme-blog-core-src-templates-post-query-js" */),
  "component---node-modules-gatsby-theme-blog-core-src-templates-posts-query-js": () => import("./../../../node_modules/gatsby-theme-blog-core/src/templates/posts-query.js" /* webpackChunkName: "component---node-modules-gatsby-theme-blog-core-src-templates-posts-query-js" */),
  "component---node-modules-gatsby-theme-notes-src-templates-note-js": () => import("./../../../node_modules/gatsby-theme-notes/src/templates/note.js" /* webpackChunkName: "component---node-modules-gatsby-theme-notes-src-templates-note-js" */),
  "component---node-modules-gatsby-theme-notes-src-templates-notes-js": () => import("./../../../node_modules/gatsby-theme-notes/src/templates/notes.js" /* webpackChunkName: "component---node-modules-gatsby-theme-notes-src-templates-notes-js" */)
}

